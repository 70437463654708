export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '新闻图片',
    prop: 'imgUrl',
    type: 'upload',
    slot: true,
    listType: 'picture-img'
  }, {
    label: '主题',
    prop: 'title'
  }, {
    label: '发布时间',
    prop: 'publishDate'
  }, {
    label: '显示顺序',
    prop: 'imgIndex'
  }, {
    width: 150,
    label: '状态',
    prop: 'publishStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待发布',
        value: 0
      }, {
        label: '发布',
        value: 1
      }
    ]
  }, {
    label: '链接',
    prop: 'imgJumpUrl'
  }, {
    label: '备注',
    prop: 'remark'
  }]
}
